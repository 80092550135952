























import { Component, Vue } from 'vue-property-decorator'
import Carousel from '../components/Carousel.vue'
import Product from '../components/Product.vue'
@Component({
    components: { Carousel, Product },
})
export default class Home extends Vue {
    data: any = {
        banner: {},
        product: [],
        status: true,
        bannerStatus:true,
    }

    created() {
        this.getBanner()
        this.getProduct()
    }

    async getBanner() {
        this.data.bannerStatus = true
        await this.$http.get('banner').then((res: any) => {
            this.data.bannerStatus = false
            this.data.banner = res.data
        })
    }
    async getProduct() {
        this.data.status = true
        await this.$http.get('product').then((res: any) => {
            this.data.status = false
            this.data.product = res.data
        })
    }
}
