



















import { Component, Vue } from 'vue-property-decorator'
@Component({
    components: {},
})
export default class Footer extends Vue {
    icons: any = ['mdi-facebook', 'mdi-twitter', 'mdi-whatsapp']

    data: any = {
        contact: [
            {
                icon: 'mdi-facebook',
                link: 'https://www.facebook.com/Starroad-108585254705614',
            },
            {
                icon: 'mdi-twitter',
                link: 'https://twitter.com/messages/1009465071909523456-1385647593192001543',
            },
            {
                icon: 'mdi-whatsapp',
                link: 'https://wa.me/message/RCL5NPLGFN2AH1',
            },
        ],
    }
}
