







































import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
    components: {},
})
export default class Product extends Vue {
    @Prop() private data!: {}
}
