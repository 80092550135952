










import { Component, Vue } from 'vue-property-decorator'
import Head from './components/Head.vue'
import Fotter from './components/Footer.vue'
@Component({
    components: { Head, Fotter },
})
export default class App extends Vue {
}
