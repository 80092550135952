import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Item from '../views/Item.vue'

Vue.use(VueRouter)

const routes : Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{public:true}
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta:{public:true}
  },
  {
    path: '/product/:id',
    name: 'ItemInfo',
    component: Item,
    props:true,
    meta:{public:false}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
