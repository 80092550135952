import Vue from 'vue'
import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})
Vue.prototype.$httpajax = http
Vue.prototype.$http = http
window.axios = http
export default http
