















import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
    components: {},
})
export default class Carousel extends Vue {
    @Prop() private data!: {}
    @Prop() private type!: ''
    config:any = {
        height:360
    }

    loadProduct() {
        const width: any = this.$refs.imgcol
        this.config.height = width.$el.clientWidth
    }

    loadBanner(){
        const width: any = this.$refs.banner
        this.config.height = width[0].$el.clientHeight
    }
}
