

































import { Component, Vue, Prop } from 'vue-property-decorator'
import Carousel from '../components/Carousel.vue'
@Component({
    components: {Carousel},
})
export default class Item extends Vue {
    @Prop() private id!: {}
    data: any = {
        status: true,
        data: {},
        height: 445,
    }

    created() {
        this.getProductById()
    }

    async getProductById() {
        this.data.status = true
        this.$http.get(`product/${this.id}`).then((res: any) => {
            this.data.data = res.data
            this.data.status = false
        })
    }

    load() {
        const width: any = this.$refs.imgcol
        this.data.height = width.$el.clientWidth
    }
}
