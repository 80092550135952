






















import { Component, Vue } from 'vue-property-decorator'
import ProductList from '../components/Product.vue'
@Component({
    components: { ProductList },
})
export default class Product extends Vue {
    data: any = {
        page: 1,
        pageLength: 0,
        product: {},
        status: true,
        keyword: '',
    }
    created() {
        this.getProductList()
    }

    async getProductList() {
        this.data.status = true
        await this.$http
            .get('productList', {
                params: {
                    limit: 8,
                    page: this.data.page,
                },
            })
            .then((res: any) => {
                this.data.status = false
                this.data.product = res.data.data
                this.data.pageLength = Math.ceil(res.data.total / 8)
            })
    }

    search() {
        console.log(this.data.keyword)
    }
}
